import Container from '../Container';
import Content from '../Content';
import Hero from '../Hero';
import Footer from '../Footer';

function App() {
	return (
		<Container>
			<Hero />
			<Content />
			<Footer />
		</Container>
	);
}

export default App;
