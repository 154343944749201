import React, { useEffect, useState } from 'react';
import { formatDistanceStrict } from 'date-fns'
import { sumBy, reduce } from 'lodash';
import { useMediaQuery } from 'react-responsive'
import { TwitterLogo, GithubLogo, DiscordLogo } from "@phosphor-icons/react";
import { useQuery, gql } from '@apollo/client';

import Button from '../Button';
import { formatCurrency } from '../../utils/number';

import Icon, { Aave, Avalanche } from '../Icon';
import roboImg from '../../assets/robo-labs.png';

import {
	Actions,
	Wrapper, 
	Intro, 
	Stats, 
	About, 
	Features, 
	Partners,
} from './Content.styled';

const inception = new Date('July 28, 2023');

const GET_LOCATIONS = gql`
	query GetStats {
		PairsCount
		PairSnapshots {
			volumeUSD
			pair { 
				tvlUSD
			}
		}
	}
`;

const Content = () => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
	const { loading, data } = useQuery(GET_LOCATIONS);
	const date = formatDistanceStrict(inception, new Date(), { unit: 'day'})

	const [vol, setVol] = useState('0');
	const [tvl, setTvl] = useState('0');


	useEffect(() => {
		if (data) {
			const vol = sumBy(data?.PairSnapshots, 'volumeUSD').toFixed(2);
			const tvl = reduce(data?.PairSnapshots, (sum, val) => sum + val.pair.tvlUSD, 0).toFixed(2);

			setVol(vol);
			setTvl(tvl);
		}
	}, [data])

	return (
		<Wrapper>
			<Intro>
				<h2>Reservoir Finance</h2>
				<p>Bringing capital efficiency to DeFi through transparency, responsible credit usage, and superfluid collateral.</p>
				<Actions>
					{ isTabletOrMobile ? (
						<Button alt small />
					) : (
						<div className='social'>
							<a
								href="https://twitter.com/ReservoirFi"
								target="_blank"
								rel="noreferrer"
							>
								<TwitterLogo size={24} weight="fill" color="#0f3846"/>
							</a>
							<a href="https://github.com/orgs/reservoir-labs/" target="_blank" rel="noreferrer">
								<GithubLogo size={24} weight="fill" color="#0f3846"/>
							</a>
							<a href="https://discord.gg/SZjwsPT7CB" target="_blank" rel="noreferrer">
								<DiscordLogo size={24} weight="fill" color="#0f3846"/>
							</a>
						</div>
					)}
				</Actions>


			</Intro>

			{ loading ? null : (
				<Stats>
					<div>
						<span>{ date }</span>
						<p>Since the inception of Reservoir</p>
					</div>
					<div>
						<span>{formatCurrency(tvl)}</span>
						<p>Total Liquidity</p>
					</div>
					<div>
						<span>{formatCurrency(vol)}</span>
						<p>Total Volume (24h)</p>
					</div>
					<div>
						<span>{data?.PairsCount}</span>
						<p>Total Pairs </p>
					</div>
				</Stats>
			)}

			<About>
				<div>
					<span>Simply Efficient DeFi.</span>
					<p>Reservoir Finance is dedicated to developing groundbreaking solutions that optimize capital efficiency. In order to achieve this goal, we foster a culture of rigorous risk assessment and open discussion. Unlike many existing decentralized finance (DeFi) platforms that heavily depend on centralized and opaque entities, our aim is to minimize lack of clarity, risk, and security. By doing so, we seek to enhance DeFi's product offerings and construct a more transparent and inclusive cryptocurrency ecosystem.</p>
				</div>
				<div>
					<Icon name='logo-outlined' />
				</div>
			</About>

			<Features>
				<div> 
					<span>Manipulation Resistant Oracles</span>
					<p>Reservoir Finance has built in, manipulation resistant oracles for live accurate pricing and near instant data recollection.</p>
				</div>
				<div>
					<span>Multi-curve AMM</span>
					<p>A generic factory & AMM toolbox for deploying various passive AMM curves. Currently, x*y=k & StableSwap curves are implemented & live.</p>
				</div>
				<div>
					<span>Asset Management</span>
					<p>Reservoir Finance’s asset manager combines the power of idle tokens with the lending capabilities of money markets and yield protocols, providing additional “Yield while you LP”.</p>
				</div>
				<div>
					<span>DeFi Protocol Risk Pricing</span>
					<p>Comprehensive research on DeFi protocol risks & pricing of their annualized risk premium.</p>
					<p>Coming soon to a platform near you!</p>
				</div>
			</Features>

			<Partners>
				<div>
					<img src={roboImg} alt='partners'/>
				</div>
				<div>
					<Aave />
				</div>
				<div>
					<Avalanche />
				</div>
			</Partners>

		</Wrapper>
	);
};

export default Content;
