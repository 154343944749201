import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #BE7A58;
  padding: 20px;
	width: 100%;
  color: #F8E0BB;
  margin-top: 100px;
  border-radius: 8px 8px 0 0;
  max-width: 1440px;
  margin: 0 auto;

  p {
    font-size: 0.75em;
  }

  a {
    font-weight: 700;
    color: #F8E0BB;

    &:hover {
      color: #0f3846;
    }
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; 

  span {
    font-family: 'Edu Whyte Inktrap';
    font-weight: 500;
    font-size: 1.2rem;
  }

  nav {
    display: flex;
    gap: 50px;

    @media only screen and (max-width: 768px) {
      gap: revert;
      justify-content: space-between;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column; 
  }
`;

const Container = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`;

const Footer = ({ className }) => {
	return (
		<Wrapper>
      <Container>
        <Links>
          <span>Reservoir Finance</span>
          <nav>
            <a
              href="https://docs.reservoir.fi/"
              target="_blank"
              rel="noreferrer"
            >
              <small>Docs</small>
            </a>
            <a href="https://medium.com/@ReservoirFinance" target="_blank" rel="noreferrer">
              <small>Blog</small>
            </a>
            <a href="https://twitter.com/ReservoirFi" target="_blank" rel="noreferrer">
              <small>Twitter</small>
            </a>
            <a href="https://discord.gg/SZjwsPT7CB" target="_blank" rel="noreferrer">
              <small>Discord</small>
            </a>
            <a href="https://github.com/orgs/reservoir-labs/" target="_blank" rel="noreferrer">
              <small>Github</small>
            </a>
          </nav>
        </Links>

        <small><a href=''>Join us</a> on our mission to push forward the frontier of DeFi & human capital efficiency.</small>
      </Container>

		</Wrapper>
	);
};

export default Footer;
