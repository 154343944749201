import { createGlobalStyle } from 'styled-components';

import EduWhyteRegularWoff from './fonts/EduWhyte-Regular.woff';
import EduWhyteRegularWoff2 from './fonts/EduWhyte-Regular.woff2';

import EduWhyteMediumOft from './fonts/EduWhyte-Medium.otf';

import EduWhyteInktrapRegularWoff from './fonts/EduWhyteInktrap-Regular.woff';
import EduWhyteInktrapRegularWoff2 from './fonts/EduWhyteInktrap-Regular.woff2';

import EduWhyteInktrapMediumWoff from './fonts/EduWhyteInktrap-Medium.woff';
import EduWhyteInktrapMediumWoff2 from './fonts/EduWhyteInktrap-Medium.woff2';

const GlobalStyle = createGlobalStyle`
	@font-face {
		font-family: 'Edu Whyte';
		src: url(${EduWhyteRegularWoff}) format('woff'), url(${EduWhyteRegularWoff2}) format('woff2');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Edu Whyte';
		src: url(${EduWhyteMediumOft}) format('opentype');
		font-weight: medium;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Edu Whyte Inktrap';
		src: url(${EduWhyteInktrapRegularWoff}) format('woff'), url(${EduWhyteInktrapRegularWoff2}) format('woff2');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Edu Whyte Inktrap';
		src: url(${EduWhyteInktrapMediumWoff}) format('woff'), url(${EduWhyteInktrapMediumWoff2}) format('woff2');
		font-weight: medium;
		font-style: normal;
		font-display: swap;
	}

	*, *:before, *:after {
		box-sizing: border-box;
	}
	
	html {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	html, body, #root {
		height: 100%;
	}

	body {
		box-sizing: border-box;
		font-family: 'Ubuntu Mono', monospace;
		background-color: #f8e0bb;
		margin: 0;
		line-height: 1.5;
	}

	h2 {
		font-family: 'Edu Whyte Inktrap';
		font-weight: 500;
	}
	
	h3 {
		color: #0f3846;	
		font-weight: 700;
	}

	a {
		text-decoration: none;
    transition: color 0.2s ease;

		&:hover {
      color: #0f3846;
    }
	}
`;

export default GlobalStyle;
