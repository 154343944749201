import React from 'react';

const LogoOutlined = ({ fill }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="304" height="432" fill="none">
      <path stroke="#BE7A58" strokeWidth="2" d="M150.812 2.806c.31-.432.579-.805.804-1.115.225.31.495.683.805 1.115 1.025 1.43 2.49 3.505 4.249 6.088 3.519 5.166 8.209 12.354 12.898 20.443 4.691 8.092 9.37 17.069 12.874 25.816 3.51 8.764 5.808 17.217 5.808 24.284 0 20.234-16.403 36.634-36.634 36.634s-36.634-16.4-36.634-36.634c0-7.067 2.298-15.52 5.809-24.284 3.504-8.747 8.183-17.724 12.873-25.816 4.689-8.09 9.38-15.277 12.898-20.443a310.046 310.046 0 0 1 4.25-6.088Zm150.965 145.912L193.381 431h-83.529L1.455 148.718h66.57c38.21 0 69.186 30.976 69.186 69.187 0 25.707-5.865 41.759-11.895 54.652-1.163 2.486-2.34 4.867-3.487 7.188-4.777 9.666-9.033 18.276-9.565 29.008-1.185 23.901 19.347 39.403 39.352 39.403 20.005 0 40.538-15.502 39.352-39.403-.532-10.732-4.788-19.343-9.565-29.008-1.147-2.321-2.324-4.702-3.487-7.188-6.03-12.893-11.895-28.945-11.895-54.652 0-38.211 30.976-69.187 69.187-69.187h66.569Z"/>
    </svg>
	);
};

export default LogoOutlined;
