import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
	margin: 0 auto;
  width: 100%;

`;

const Container = ({ children }) => {
	return (
		<Wrapper>{ children }</Wrapper>
	);
};

export default Container;
