import React from "react";
import styled from "styled-components";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { List } from "@phosphor-icons/react";

const Trigger = styled(DropdownMenu.Trigger)`
  align-items: center;
  background: #0f3846;
  border: none;
  display: flex;
  padding: 8px;
  border-radius: 8px;
  unset: all;
`;

const Content = styled(DropdownMenu.Content)`
  min-width: 120px;
  background: #0f3846;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
`;

const Item = styled(DropdownMenu.Item)`
  line-height: 1;
  color: #f8e0bb;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  position: relative;
  user-select: none;
  outline: none;
`;

const Dropdown = ({ fill }) => {

  return (
    <DropdownMenu.Root>
      <Trigger asChild>
        <button aria-label="Customise options">
          <List size={20} color="#f8e0bb"/>
        </button>
      </Trigger>

      <DropdownMenu.Portal>
        <Content align="end" sideOffset={5}>
          <Item>
            Docs
          </Item>
          <Item>
            Blog
          </Item>
          <Item>
            Twitter
          </Item>
          <Item>
            Discord
          </Item>

        </Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default Dropdown;


