import React from 'react';
import styled from 'styled-components';

import Background from '../../assets/hero-bg.png';

import Header from '../Header';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 30px;
  border-radius: 0 0 6px 6px;
`;

const StyledHeader = styled(Header)`
  position: absolute;
  top: 30px;
  left: 0px;
  padding: 0 40px;
`;

const Bg = styled.div`
  width: 100%;
  height: auto;

  img {
    width: 100%;
    display: block;
  }
`;

const Hero = ({ fill }) => {
	return (
		<Wrapper>
			<StyledHeader />
      <Bg>
        <img src={Background} alt='hero background'/>
      </Bg>
		</Wrapper>
	);
};

export default Hero;
