import React from 'react';
import styled from 'styled-components';

import Icon from '../Icon';
import Nav from '../Nav';

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const Header = ({ className }) => {
	return (
		<Wrapper className={className}>
			<Icon name='logo' />
      <Nav />
		</Wrapper>
	);
};

export default Header;
