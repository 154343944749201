import React from 'react';
import ReactDOM from 'react-dom/client';
import WebFont from 'webfontloader';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import GlobalStyles from './globalStyles';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {Analytics} from "@vercel/analytics/react";

WebFont.load({
	google: {
		families: ['Zilla Slab:400,700', 'serif'],
	},
});

const client = new ApolloClient({
  uri: 'https://data.staging.arkiver.net/robolabs/reservoir-mainnet-v2/graphql',
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<App />
			<Analytics />
		</ApolloProvider>
		<GlobalStyles />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
