import React from "react";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import Dropdown from "../Dropdown/Dropdown";

import Button from "../Button";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  a {
    color: white;
  }
`;

const Nav = ({ fill }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <>
    {isTabletOrMobile ? (
      <Dropdown />
    ) : (
      <Wrapper>
        <a
          href="https://docs.reservoir.fi/"
          target="_blank"
          rel="noreferrer"
        >
          Docs
        </a>
        <a href="https://medium.com/@ReservoirFinance" target="_blank" rel="noreferrer">
          Blog
        </a>
        <a href="https://twitter.com/ReservoirFi" target="_blank" rel="noreferrer">
          Twitter
        </a>
        <a href="https://discord.gg/SZjwsPT7CB" target="_blank" rel="noreferrer">
          Discord
        </a>
        <Button>
          Launch App
        </Button>
      </Wrapper>
    )}
    </>

  );
};

export default Nav;
