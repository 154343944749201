import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div``;

const StyledButton = styled.button`
	color: #FFFFFF;
	background: #0f3846;
	display: inline-block;
	padding: 12px;
	border: none;
	border-radius: 6px;
	box-sizing: border-box;
	text-decoration: none;
	font-family: 'Edu Whyte';
	font-weight: 500;
	font-size: 1rem;
	text-align: center;
	width: 100%;
	transition: all 0.2s ease;

	${({ small }) => small && css`
		padding: 8px;
		font-size: 0.8rem;
	`};

	${({ alt }) => alt && css`
		background: #be7a58;
	`};

	&:hover {
		background: #F8E0BB;
		color: #0f3846;
		cursor: pointer;
	}
`;

const Button = ({ alt, small }) => {
	return (
		<Wrapper>
			<a href="https://app.reservoir.fi">
				<StyledButton alt={alt} small={small}>Launch App</StyledButton>
			</a>
		</Wrapper>
	);
};

export default Button;
