import React from 'react';

export const Aave = ({ fill }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" dataname="Layer 1" viewBox="0 0 800 220">
			<defs>
				<linearGradient id="a" x1="402.38" x2="416.27" y1="608.02" y2="611.26" gradientTransform="matrix(55.73 0 0 -61.13 -22399.38 37390.17)" gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#31bac6"/>
					<stop offset="1" stopColor="#b6509e"/>
				</linearGradient>
				<linearGradient xlinkHref="#a" id="b" x1="398.46" x2="416.8" y1="607.05" y2="610.33" gradientTransform="matrix(42.21 0 0 -60.24 -16781.26 36852.38)"/>
				<linearGradient xlinkHref="#a" id="c" x1="403.19" x2="417.06" y1="608.4" y2="611.63" gradientTransform="matrix(55.78 0 0 -61.13 -22470.38 37393.23)"/>
				<linearGradient xlinkHref="#a" id="d" x1="404.08" x2="417.96" y1="609.27" y2="612.51" gradientTransform="matrix(55.78 0 0 -61.13 -22536.12 37390.77)"/>
			</defs>
			<g dataname="Group 4051">
				<path d="M490.08 203.2 415.34 21.93a15.83 15.83 0 0 1-.92-5.15 14.2 14.2 0 0 1 3.67-10.1A12.86 12.86 0 0 1 428 2.83a13.31 13.31 0 0 1 7.71 2.57 16.37 16.37 0 0 1 5.33 6.79l71.07 177.42 71.08-177.42a16.27 16.27 0 0 1 5.32-6.79 13.31 13.31 0 0 1 7.71-2.57 12.86 12.86 0 0 1 9.92 3.85 14.2 14.2 0 0 1 3.67 10.1 15.77 15.77 0 0 1-.92 5.14L534.15 203.2c-4.21 9.37-10.47 14-18.73 14h-6.61c-8.26-.04-14.51-4.63-18.73-14Z" dataname="Path 2431" style={{ fill: 'url(#a)' }} />
				<path d="M641.58 211.65a13.31 13.31 0 0 1-3.86-9.74V18.25a14 14 0 0 1 3.86-9.91 12.74 12.74 0 0 1 9.73-4H772.7a12.07 12.07 0 0 1 9.18 3.85 12.37 12.37 0 0 1 3.86 9.19 11.7 11.7 0 0 1-3.86 9A12.93 12.93 0 0 1 772.7 30H665.27v66.87h96.59a12.08 12.08 0 0 1 9.18 3.86 12.6 12.6 0 0 1 3.86 9.18 11.7 11.7 0 0 1-3.86 9 12.84 12.84 0 0 1-9.18 3.67h-96.59v67.22H772.7a12 12 0 0 1 9.18 3.86 12.33 12.33 0 0 1 3.86 9.18 11.7 11.7 0 0 1-3.86 9 12.93 12.93 0 0 1-9.18 3.67H651.31a12 12 0 0 1-9.73-3.86Z" dataname="Path 2432" style={{ fill: 'url(#b)' }}/>
				<path d="M432.23 198.06 357.5 16.78C353.29 7.42 347 2.83 338.77 2.83h-6.62c-8.27 0-14.51 4.59-18.73 14l-32.51 79h-24.6a13.49 13.49 0 0 0-13.41 13.41v.18a13.48 13.48 0 0 0 13.41 13.41h13.22l-31 75.3a15.81 15.81 0 0 0-.92 5.14 14.19 14.19 0 0 0 3.67 10.1 12.87 12.87 0 0 0 9.92 3.86 13.45 13.45 0 0 0 7.71-2.57 16.46 16.46 0 0 0 5.33-6.8l34.15-85h23.49a13.49 13.49 0 0 0 13.41-13.41V109a13.48 13.48 0 0 0-13.41-13.41h-12.7l26.27-65.2 71.07 177.42a16.27 16.27 0 0 0 5.32 6.79 13.31 13.31 0 0 0 7.72 2.57 12.86 12.86 0 0 0 9.91-3.85 14.21 14.21 0 0 0 3.67-10.11 12 12 0 0 0-.91-5.15Z" dataname="Path 2433" style={{ fill: 'url(#c)' }}/>
				<path d="M208.93 198.06 134.2 16.78C130 7.42 123.72 2.83 115.45 2.83h-6.61c-8.26 0-14.5 4.59-18.73 14l-32.5 79H33a13.5 13.5 0 0 0-13.41 13.41v.18A13.5 13.5 0 0 0 33 122.76h13.22l-31 75.3a15.81 15.81 0 0 0-.92 5.14 14.17 14.17 0 0 0 3.67 10.1 12.87 12.87 0 0 0 9.92 3.86 13.45 13.45 0 0 0 7.71-2.57 16.46 16.46 0 0 0 5.33-6.8l34.15-85h23.66a13.49 13.49 0 0 0 13.41-13.41V109a13.48 13.48 0 0 0-13.41-13.41H86.07l26.08-65.21 71.07 177.41a16.3 16.3 0 0 0 5.33 6.8 13.45 13.45 0 0 0 7.71 2.57 12.91 12.91 0 0 0 9.92-3.86 14.2 14.2 0 0 0 3.67-10.1 12.21 12.21 0 0 0-.92-5.14Z" dataname="Path 2434" style={{ fill: 'url(#d)' }}/>
			</g>
		</svg>
	);
};

