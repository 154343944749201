import { Wrapper } from './Icon.styled';

import Logo from './Icons/Logo';
import LogoOutlined from './Icons/LogoOutlined';

const Icon = ({ className, width, name, height }) => {
  const sanitizedName = name.toLowerCase();

  const renderComponent = () => {
    switch (sanitizedName) {
      case 'logo':
        return <Logo />;
      case 'logo-outlined':
        return <LogoOutlined />;
      default:
        return null;
    }
  };

  return (
    <Wrapper className={className} width={width} height={height}>
      {renderComponent()}
    </Wrapper>
  );
};

export default Icon;
