import styled from 'styled-components';

import introBG from '../../assets/intro-bg.png';
import statBG from '../../assets/stat-bg.png';
import aboutBG from '../../assets/about-bg.png';
import featureBG from '../../assets/feature-bg.png';
import oraclesBG from '../../assets/oracles.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
	padding-right: 20px;
`;

export const Intro = styled.div`
	padding: 12px;
	background-image: url(${introBG});
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 30px;	

	h2 {
		color: #0f3846;	
		font-weight: 700;
		font-size: 3em;
    line-height: 1;
    letter-spacing: 0.1rem;
		margin: 0;
	}

	p {
		background: white;
		color: #0f3846;
		border-radius: 6px;
		padding: 0 8px;
    font-size: 1rem;
    margin: 8px 0;
    margin-bottom: 12px;
	}

	.social {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		a {
			margin-left: 12px;
		}
	}

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const Stats = styled.div`
  display: flex;
  color: #0F3846;
  gap: 32px;
  margin-bottom: 30px;

	div {
		flex: 1;
		text-align: center;
		border: 2px solid #0F3846;
		border-radius: 6px;
    padding: 12px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-image: url(${statBG});
	  background-size: cover;
	  background-repeat: no-repeat;

    @media only screen and (max-width: 768px) {
      background-image: none;
    }
	}

  span {
    font-family: 'Edu Whyte';
		font-weight: medium;
    font-size: 2em;
    margin: 0;
    line-height: 1;
  }

  p {
    margin: 0;
    margin-top: 4px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const About = styled.div`
  background-image: url(${aboutBG});
  background-color: #0F3846;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  overflow: hidden;
  padding: 24px;
  border-radius: 6px;
  display: flex;
  margin-bottom: 30px; 

  div:nth-of-type(1) {
    flex: 3;
    display: flex;
    justify-content: center;
    flex-direction: column;

    span {
      font-family: 'Edu Whyte Inktrap';
      font-weight: 500;
      font-size: 2.25rem;
      letter-spacing: 0.1rem;
    }
  }

  div:nth-of-type(2) {
    flex: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 50px;
  }

  p {
    font-size: 1.25rem;
  }

  @media only screen and (max-width: 768px) {
    div:nth-of-type(2) {
      display: none;
    }
  }
`;

export const Features = styled.div`
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px;
  margin-bottom: 30px;

  div {
    flex: 1;
    border-radius: 6px;
    padding: 12px;
    background-image: url(${featureBG});
    background-size: cover;
	  background-repeat: no-repeat;

    span {
      font-family: 'Edu Whyte';
      font-weight: 500;
      font-size: 1.25rem;
    }

    &:nth-of-type(1) {
      background-image: url(${oraclesBG});
      border: 2px solid #0F3846;
      color: #0F3846;
    }

    &:nth-of-type(2) {
      background-color: #BE7A58;
    }

    &:nth-of-type(3) {
      background-color: #0F3846;
    }

    &:nth-of-type(4) {
      background-color: #79642F;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Partners = styled.div`
  align-items: center;
  max-width: 600px; 
  margin: 100px auto;
  display: flex;
  gap: 30px;
  justify-content: center;
  
  div {
    flex: 1;

    svg {
      width: 100%;
    }
  }
  
  img {
    width: 100%;
  }
`;


export const Actions = styled.div`
	display: flex;
	justify-content: flex-end;

	@media only screen and (max-width: 768px) {
		justify-content: center;
	}
`;