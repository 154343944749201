import React from 'react';

export const Avalanche = ({ fill }) => {
	return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 3195 648">
      <path fill="#fff" d="M117.962 92.9h413.206v405.051H117.962z"/>
      <path fill="#E84142" d="M864.357 210.947h34.722l97.158 225.535h-45.871l-21.025-51.606h-97.795l-20.388 51.606h-44.916l98.115-225.535Zm50.968 139.526-34.722-91.743-35.36 91.743h70.082ZM1039.29 210.947h46.19l61.48 170.425 63.39-170.425h43.33l-91.11 225.535h-34.72l-88.56-225.535ZM1397.12 210.947h34.73L1529 436.482h-45.87l-21.02-51.606h-97.8l-20.39 51.606h-44.91l98.11-225.535Zm50.97 139.526-34.72-91.743-35.36 91.743h70.08ZM1617.78 210.947h40.14v189.22h95.88v36.315h-136.02V210.947ZM1919.62 210.947h34.73l97.15 225.535h-45.87l-21.02-51.606h-97.8l-20.39 51.606h-44.91l98.11-225.535Zm50.97 139.526-34.72-91.743-35.36 91.743h70.08ZM2140.28 210.947h53.2l109.26 167.558h.64V210.947h40.13v225.535h-50.96l-111.5-172.974h-.63v172.974h-40.14V210.947ZM2612.34 263.826c-8.28-8.919-16.35-14.865-24.21-17.839-7.65-2.973-15.4-4.459-23.25-4.459-11.68 0-22.3 2.123-31.86 6.371-9.34 4.035-17.41 9.769-24.21 17.202-6.8 7.22-12.1 15.715-15.93 25.484-3.61 9.769-5.41 20.281-5.41 31.536 0 12.105 1.8 23.255 5.41 33.448 3.83 10.194 9.13 19.007 15.93 26.44 6.8 7.433 14.87 13.273 24.21 17.521 9.56 4.247 20.18 6.371 31.86 6.371 9.13 0 17.94-2.124 26.44-6.371 8.7-4.46 16.77-11.468 24.21-21.025l33.12 23.573c-10.19 14.016-22.61 24.21-37.27 30.581-14.65 6.371-30.26 9.557-46.82 9.557-17.42 0-33.45-2.761-48.1-8.283-14.45-5.734-26.98-13.697-37.59-23.891-10.41-10.406-18.59-22.83-24.53-37.271-5.95-14.441-8.92-30.475-8.92-48.101 0-18.051 2.97-34.404 8.92-49.057 5.94-14.866 14.12-27.502 24.53-37.908 10.61-10.406 23.14-18.37 37.59-23.891 14.65-5.734 30.68-8.601 48.1-8.601 15.29 0 29.41 2.761 42.36 8.282 13.17 5.309 25.38 14.441 36.64 27.396l-31.22 22.935ZM2739.31 210.947h40.13v89.831h105.44v-89.831h40.14v225.535h-40.14v-99.389h-105.44v99.389h-40.13V210.947ZM3039.13 210.947h149.4v36.315h-109.26v55.428h103.53v36.315h-103.53v61.162h115v36.315h-155.14V210.947Z"/>
      <path fill="#E84142" fillRule="evenodd" d="M647.43 323.744c0 178.664-144.836 323.5-323.5 323.5S.43 502.408.43 323.744 145.266.244 323.93.244s323.5 144.836 323.5 323.5Zm-415.17 128.73h-62.782c-13.192 0-19.709 0-23.683-2.543-4.291-2.782-6.914-7.391-7.232-12.477-.238-4.689 3.02-10.411 9.537-21.855L303.117 142.36c6.596-11.603 9.934-17.404 14.146-19.55a15.998 15.998 0 0 1 14.464 0c4.212 2.146 7.55 7.947 14.146 19.55l31.868 55.63.163.284c7.124 12.448 10.737 18.76 12.314 25.385a47.194 47.194 0 0 1 0 22.093c-1.589 6.676-5.166 13.034-12.398 25.67l-81.426 143.939-.211.369c-7.172 12.55-10.806 18.91-15.843 23.711a47.363 47.363 0 0 1-19.311 11.205c-6.596 1.828-13.987 1.828-28.769 1.828Zm158.546.007h89.962c13.272 0 19.947 0 23.921-2.622 4.291-2.782 6.993-7.471 7.232-12.557.23-4.534-2.958-10.035-9.205-20.813l-.65-1.121-45.06-77.087-.513-.867c-6.332-10.709-9.53-16.116-13.633-18.206a15.825 15.825 0 0 0-14.384 0c-4.133 2.145-7.471 7.788-14.067 19.152l-44.901 77.087-.154.266c-6.573 11.346-9.858 17.016-9.621 21.669.318 5.086 2.94 9.775 7.232 12.556 3.894 2.543 10.569 2.543 23.841 2.543Z" clipRule="evenodd"/>
    </svg>
	);
};

